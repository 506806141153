<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo />
        <!-- End Header Area -->
        <!-- Start Slider Area  -->

        <div class="rn-service-area rn-section-gapDoubleTop bg_color--1">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="section-title text-center mb--20 mb_sm--0">
                            <h2 class="heading-title">Things to do</h2>
                            <p>
                                During your stay, should you be looking for something to do or somewhere to eat, here are some of our favourite suggestions around the Geelong region.
                            </p>
                        </div>
                    </v-col>
                </v-row>
                <!-- End .row -->
                <Todo class="rn-section-halfGapBottom" />
            </v-container>
        </div>

        <FooterTwo />
    </div>
</template>

<script>
    import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo";
    import Todo from "../../components/things/todo";
    import FooterTwo from "../../components/footer/FooterTwo";

    export default {
        components: {
            HeaderOnePageTwo,
            Todo,
            FooterTwo
        },
        data() {
            return {};
        },
    };
</script>

<style lang="scss">

    .feather-menu {
        color: #1d1d24;
    }
</style>
