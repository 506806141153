<template>
    <v-row>
        <v-col md="4"
               sm="6"
               cols="12"
               class="mt--30"
               v-for="(service, i) in serviceContent"
               :key="i">
            <div class="standard-service  super-center text-center ">
                <div class="super-center">
                    <img :src="service.src" alt="Corporate Images" class="super-center removeDisplay" />
                </div>
                <div class="content super-center">
                    <h3>
                        {{ service.title }}
                    </h3>
                    <p class="text-center">
                        {{ service.desc }}
                    </p>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        data() {
            return {
                serviceContent: [
                    {
                        src: require("../../assets/images/things/waterfront.png"),
                        title: "Waterfront Walk",
                        desc: `Geelong's waterfront hosts an easy, flat walk to explore the best of the city. Find the painted bollards along the way as you explore the Ferris wheel, historic carousel, pools, piers and gardens.`,
                    },
                    {
                        src: require("../../assets/images/things/gardens.png"),
                        title: "Botanical Gardens",
                        desc: `The Botanical Gardens are a great place to relax and explore a kaleidoscope of colourful blooms and impressive plants and trees from around the world.`,
                    },
                    {
                        src: require("../../assets/images/things/mopa.png"),
                        title: "Museum of Play & Art",
                        desc: `MOPA is Geelong's Children's Museum - a creative space for children and families to play and learn together, catering for kids aged 1-10. Also check out other cultural sites at the Geelong Gaol Museum, the National Wool Museum and Geelong Gallery.`,
                    },
                    {
                        src: require("../../assets/images/things/gor.png"),
                        title: "Great Ocean Road",
                        desc: `If you have access to a car, we highly recommend going for a drive down the Great Ocean Road. There are countless things to see, including the 12 Apostles, Erskine Falls, Bells Beach and the Split Point Lighthouse (from Round the Twist!). Check out visitgreatoceanroad.org.au for all the deets.`,
                    },
                    {
                        src: require("../../assets/images/things/wine.png"),
                        title: "Wineries/Breweries",
                        desc: `There are a plethora of wineries, breweries and distilleries around Geelong with something to tickle everyone's fancy. We recommend Mt Duneed Estate Winery, Clyde Park Vineyard, Little Creatures Brewery, Flying Brick Cider House and Anther Gin Distillery.`,
                    },
                    {
                        src: require("../../assets/images/things/youyangs.png"),
                        title: "You Yangs",
                        desc: `The You Yangs Regional Park is located 22km north of Geelong and features a bunch of different hikes and walks of varying difficulty. The granite peaks of Big Rock and Flinders Peak have panoramic views out to Melbourne and across the countryside.`,
                    },
                    {
                        src: require("../../assets/images/things/wahwah.png"),
                        title: "Wah Wah Gee",
                        desc: `Jess's pick - Asian fusion with fun vibes located over the water on Cunningham Pier, with good cocktails!`,
                    },
                    {
                        src: require("../../assets/images/things/leega.png"),
                        title: "Leega Korean BBQ",
                        desc: `Josh's pick - authentic Korean BBQ where you cook over your own BBQ on your table!`,
                    },
                    {
                        src: require("../../assets/images/things/mavs.png"),
                        title: "Mavs",
                        desc: `Family style Greek food to make your mouth water.`,
                    },
                    {
                        src: require("../../assets/images/things/18th.png"),
                        title: "18th Amendment",
                        desc: `Speakeasy style bar with vintage furnishings and probably Geelong's most extensive cocktail list! `,
                    },
                    {
                        src: require("../../assets/images/things/edge.png"),
                        title: "Edge Geelong",
                        desc: `An airy, easygoing bar on the waterfront with outdoor seating, bay views and food everyone will enjoy.`,
                    },
                    {
                        src: require("../../assets/images/things/alma.png"),
                        title: "ALMA",
                        desc: `Contemporary finer dining with the flavours of South America. `,
                    },
                                        {
                        src: require("../../assets/images/things/frankie.jpg"),
                        title: "Frankie",
                        desc: `Super yum Pan-Asian-fusion restaurant with great cocktails and a nice, fresh interior. Must tries include Korean Fried Cauliflower , Pork Gyoza and Popcorn Chicken!`,
                    },
                                        {
                        src: require("../../assets/images/things/hecho.jpg"),
                        title: "Hecho En Mexico",
                        desc: `Street-style Mexican with views over the water. Enormous menu with something for everyone`,
                    },
                                        {
                        src: require("../../assets/images/things/tomodachi.jpg"),
                        title: "Tomodachi",
                        desc: `Japanese grill with all your favourites - sashimi, bento, ramen & more`,
                    },
                                        {
                        src: require("../../assets/images/things/pistol.jpg"),
                        title: "Pistol Pete's",
                        desc: `Down-to-earth hangout offering Southern-American/Cajun grub, cocktails, live blues and jazz music.`,
                    },
                                        {
                        src: require("../../assets/images/things/parkers.jpg"),
                        title: "Parkers Steakhouse",
                        desc: `Farmhouse-style stone and wood restaurant, offering grilled meat dishes from regional producers.`,
                    },
                                        {
                        src: require("../../assets/images/things/piano.jpg"),
                        title: "Piano Bar",
                        desc: `Our absolute fave. Live piano based on the crowd's requests and all the dancing, drag bingo & shows you could ask for!`,
                    },
                                        {
                        src: require("../../assets/images/things/beavs.jpeg"),
                        title: "Beav's",
                        desc: `Pretty fun vibes with some of the weirdest decor around. Often has live music, open mics and karaoke - does pop off a bit later in the evening!`,
                    },
                                        {
                        src: require("../../assets/images/things/cellardoor.jpg"),
                        title: "Geelong Cellar Door",
                        desc: `A slightly more refined option - offering a huge selection of local wines, craft beers and ciders.`,
                    },
                                        {
                        src: require("../../assets/images/things/lambies.jpg"),
                        title: "Lamby's",
                        desc: `If you feel like going all-out, this is our pick of the nightclubs. Basement level with sticky carpets, but throws back to more millennial friendly tunes.`,
                    }
                ],
            };
        },
    };

</script>


<style lang="scss">
    .super-center {
        text-align: center;
    }

    .removeDisplay {
        display: normal !important;
    }
</style>
